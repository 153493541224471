import { useEffect, useState, FunctionComponent } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import '../globalStyles.css';

type Props = {
  Component: FunctionComponent<AppProps>;
  pageProps: AppProps;
};

function MyApp({ Component, pageProps }: Props) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => { setIsMounted(true); }, []);

  return isMounted && (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
